@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800;900&display=swap");
@import "react-alice-carousel/lib/alice-carousel.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-size: 10px;
  background-color: var(--background-color);
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: Inter, "";
}

@media screen and (min-width: 1200px) {
  html,
  body {
    font-size: 10px !important;
  }
}

@media screen and (max-width: 600px) {
  html,
  body {
    font-size: 62.5% !important;
  }
}
:root {
  --gray: #d9dee6;
  --black: #000;
  --blue: #213669;
  --white: #fff;
  --red: #c03733;
  --white-03: rgba(255, 255, 255, 0.3);
  --gray20: #616161;
  --yellow: #f8ce46;
  --gray-text: #343434;
  --blue-background: #eff3f7;
  --cs-1: #853535;
  --black-sc: #0f0f0f;
  --blue-10: #3a3a4e;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.content {
  min-height: 100vh;
}

.default-button {
  background-color: var(--blue);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem 2.5rem;
  gap: 5px;
  border-radius: 10px;
  cursor: pointer;
}

.default-button:disabled {
  opacity: 0.7;
}

.default-button.sidebar-btn {
  padding: 11px 16px;
  margin-bottom: 10px;
}

.default-button.sidebar-btn p {
  font-size: 12px;
}

.default-button.big-padding {
  padding: 1.4rem 2.5rem !important;
}

.default-button p {
  color: var(--white);
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
}

.default-button.white {
  background-color: var(--white);
}

.default-button.border {
  border: 1px solid #d9dee6;
}

.default-button.white p {
  color: var(--black);
}
.default-button.red {
  background-color: var(--red);
  padding-left: 6rem;
  padding-right: 6rem;
  align-items: center;
}

.px-st {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.default-button.red p {
  font-style: normal;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 24px;
}

.default-button.red p span {
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 24px;
}

.default-button.yellow {
  background-color: var(--yellow);
  justify-content: space-between;
}

.default-button.center {
  justify-content: center !important;
}

.default-button.start {
  justify-content: start !important;
}

.default-button.yellow p {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: var(--gray-text);
}

.section-divider {
  height: 10rem;
}

.section-blue {
  background-color: var(--blue-background);
  border-radius: 20px;
  padding-top: 4rem;
  padding-bottom: 6rem;
}

.section-blue-padding {
  background-color: var(--blue-background);
  border-radius: 20px;
  padding: 4rem;
}

.book-banner-image {
  width: 38rem;
  height: 58rem;
  border-radius: 20px;
  object-fit: cover;
}

.section-blue.padding {
  padding: 4rem;
}

.creator-desc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 60%;
  color: var(--gray20);
  margin-bottom: 4rem;
}

.creator-image {
  width: 500px;
  height: 360px;
  object-fit: cover;
  border-radius: 12px;
  object-position: 0% 0%;
}

.logo-title {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
  color: var(--gray-text);
  margin-bottom: 3px;
}

.logo-desc {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: var(--gray-text);
}

.p2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.vh-100 {
  height: 100svh;
}

.default-button.big-padding {
  padding-top: 22px;
  padding-bottom: 22px;
}

.p3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 24px !important;
}

.test-wrap {
  height: 100vh;
  background-color: #eff3f7;
  display: flex;
  padding-top: 8.5rem;
}

.test-wrap h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  /* identical to box height */

  /* Dark Grey Text */

  color: var(--gray-text);
  align-self: center;
}

.test-container {
  background-color: var(--white);
  border-radius: 20px 20px 0px 0px;
  padding: 6rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.desc-r {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Text black */

  color: #0f0f0f;
  margin-bottom: 6rem;
}

.discrete-counter {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--gray-text);
}

.circle-counter {
  width: 14px;
  height: 14px;
  background: var(--gray);
  border-radius: 14px;
  z-index: 2;
}

.lineSelect {
  height: 4px;
  position: absolute;
  background-color: #d9d9d9;
  width: calc(100% - 5rem);
  top: calc(2.5rem + 5px);
  border-radius: 3px;
  z-index: 1;
}

.discrete-lsc {
  cursor: pointer;
}

.circle-selected {
  width: 22px;
  height: 22px;
  background-color: #213669;
}

.test-toast {
  background-color: #eff3f7;
  height: 80px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #0f0f0f;
  font-size: 16px;
}

.dot-indicator {
  background-color: #d9dee6;
  width: 28px !important;
  height: 5px;
  border-radius: 3px;
  margin-left: 3px;
  margin-right: 3px;
}
.dot-indicator-selected {
  background-color: #616161;
}

@media screen and (max-width: 600px) {
  .default-button p {
    font-size: 12px !important;
    line-height: 16px;
  }

  .default-button p span {
    font-size: 12px !important;
  }

  .section-padding {
    padding: 10px;
  }

  .creator-image {
    height: 214px;
    object-fit: cover;
    border-radius: 12px;
    margin-bottom: 20px;
    background-position: center;
    object-position: 0% 0%;
    width: 100%;
  }

  .creator-desc {
    width: 100%;
    font-size: 12px;
    line-height: 16px;
  }

  .section-divider {
    height: 4rem;
  }
  .section-blue {
    padding: 10px;
  }

  .section-blue.padding {
    padding: 10px;
  }
  .default-button.yellow {
    justify-content: center;
  }

  .book-banner-image {
    width: 140px;
    height: 200px;
    margin-bottom: 15px;
    object-fit: cover;
  }
  .section-blue-padding {
    padding: 10px;
  }
  .p2 {
    font-size: 12px;
    line-height: 16px;
  }
  .test-toast {
    padding: 20px;
    font-size: 12px;
    line-height: 16px;
    height: 80px;
    display: flex;
  }
  .test-toast p {
    margin-right: 10px;
    flex: 3;
  }
  .test-toast .default-button {
    flex: 1;
    padding: 8px 10px;
  }
}
