.header {
  height: 9rem;
  background-color: var(--gray);

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 20;
}
.ic-logo {
  margin-right: 1.5rem;
}

.header-link {
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 17px;
  margin-left: 2rem;
  margin-right: 2rem;
  color: var(--black);
}

.header-link.white {
  color: var(--white);
}

.footer {
  background-color: var(--black);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5.4rem 6rem;
}

.footer-link {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--white);
}

.footer .logo-title {
  color: var(--white) !important;
}
.footer .logo-desc {
  color: var(--white) !important;
}

.header-left {
  flex: 1;
}
.header-center {
  flex: 1;
  align-items: center;
  justify-content: center;
}

.grip-title {
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
}

.section-title {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
}

.section-title.small {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 24px;
}

.section-title.small span {
  color: #616161;
}

.header-bb {
  border-bottom-width: 1px;
  border-bottom-color: #dedede;
  padding-bottom: 2rem;
}

.ic-language {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.layout-container {
  padding-top: 14rem;
  padding-bottom: 4rem;
  padding-left: 4rem;
  padding-right: 4rem;
  min-height: calc(100vh - 14rem);
}

.header-padding {
  padding-left: 4rem;
  padding-right: 4rem;
}

.social-login {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.social-login .card {
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 12px;
  padding: 16px;
}

.social-login .card h2 {
  font-size: 18px;
  margin-bottom: 2rem;
  font-weight: 700;
}

@media screen and (max-width: 600px) {
  .section-title {
    font-size: 18px !important;
    line-height: 24px;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  .header {
    height: 60px;
    padding: 10px;
    align-items: center;
  }
  .ic-logo {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  .logo-title {
    font-size: 14px;
    line-height: 14px;
  }
  .logo-desc {
    font-size: 10px;
    line-height: 10px;
  }
  .footer {
    flex-direction: column;
    height: 120px;
    padding: 10px;
    align-items: start;
  }
  .ic-social {
    width: 22px;
    height: 22px;
  }
  .footer .logo-title {
    color: var(--white) !important;
  }
  .footer .logo-desc {
    color: var(--white) !important;
  }

  .sidebar {
    position: fixed;
    width: auto;
    height: 100%;
    background-color: var(--white);
    z-index: 22;
    top: 0;
    display: flex;
    transition: 0.2s linear;
    right: 0;
    padding: 18px 14px;
    padding-bottom: 40px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .sidebar-filter {
    left: 0;
    position: fixed;
    width: 70vw;
    height: 100vh;
    background-color: var(--white);
    z-index: 24;
    top: 0;
    display: flex;
    transition: 0.2s ease-in;
    padding: 18px 14px;
    padding-bottom: 40px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .sidebar-closed {
    right: -100vw;
  }
  .ic-close-menu {
    width: 24px;
    height: 24px;
  }
  .sidebar-item {
    font-size: 14px;
    line-height: 16px;
    text-align: end;
    font-weight: 400;
    margin-bottom: 15px;
  }
  .sidebar-navigation {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
  }
  .language {
    font-size: 12px;
    line-height: 16px;
  }
  .layout-container {
    padding-top: 8rem;
    padding-left: 0;
    padding-right: 0;
  }
  .sidebar-closed-filter {
    left: -100vw;
  }
  .header-bb {
    border-bottom-width: 0;
  }
  .header-padding {
    padding-left: 0;
    padding-right: 0;
  }

  .ic-menu {
    width: 30px;
    height: 30px;
  }
  .footer-link {
    font-size: 12px;
  }
}
