.banner-wrap {
  background-color: var(--blue);
  border-radius: 20px;
  padding: 4rem;
}

.banner-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 3;
  margin-right: 2rem;
}

.banner-title {
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 77px;
  color: var(--white);
  margin-bottom: 0.5rem;
}
.banner-desc {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 135%;
  color: var(--white);
  margin-bottom: 20px;
}

.banner-author {
  font-size: 2rem;
  color: #fff;
  font-weight: 700;
}

.tag-wrap {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  gap: 15px;
  background-color: var(--white-03);
  border-radius: 10px;
}

.info-title {
  font-style: normal;
  font-weight: 300;
  font-size: 2rem;
  /* identical to box height */

  text-transform: uppercase;

  color: #ffffff;
}

.book-card-wrap {
  display: flex;
  flex-direction: column;
  /* Grey */
  background-color: var(--white);
  border: 1px solid #d9dee6;
  border-radius: 20px;
  padding: 2rem;
  cursor: pointer;
  width: 26rem;
}

.book-card-wrap.big {
  display: flex;
  flex-direction: column;
}

.book-card-wrap.small {
  width: 240px;
  height: "250px";
  display: block;
  flex: none;
  flex-direction: column;
  background-color: var(--white);
  margin-right: 3rem;
  flex-shrink: 0;
}

.book-card-wrap.small .book-card-autor {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: var(--black);
  margin-bottom: 0.5rem;
}

.book-card-wrap.small .book-card-autor span {
  color: var(--cs-1);
}

.book-card-wrap.small h4 {
  font-size: 16px;
}

.book-card-wrap.small .book-card-price {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.book-card-image {
  width: 16rem;
  height: 24rem;
  object-fit: cover;
  border-radius: 20px;
  align-self: center;
  filter: drop-shadow(5px 5px 25px rgba(0, 0, 0, 0.25));
}

.carousel {
  overflow: scroll;
  margin-bottom: 7rem;
}

.auto-cols-max {
}

.book-card-autor {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--black);
  margin-bottom: 0.5rem;
}

.book-card-autor span {
  color: var(--gray20);
}

.book-card-wrap h4 {
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 400;
  margin-bottom: 2rem;
}

.book-card-price {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.about-desc {
  font-size: 20px;
  line-height: 26px;
}

.about-desc span {
  font-weight: 500;
}

.ic-favorite {
  margin-bottom: 10px;
}

.menu-link {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 170%;
  color: var(--gray20);
  margin-right: 12px;
}

.menu-link.nav-current {
  color: #0f0f0f;
}

.nav-circle {
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background-color: #c7c7c7;
}

.margin-12 {
  margin-right: 12px;
}

.filter-title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 170%;
  display: flex;
  align-items: center;
}

.book-banner-title {
  font-style: normal;
  font-weight: 800;
  font-size: 5.2rem;
  line-height: 63px;
}

.book-banner-info {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--black-sc);
}

.book-banner-info span {
  color: var(--gray20);
}

.book-info {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.book-review-info {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.w-half {
  width: 50%;
}

.chapter-numbers {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  text-transform: capitalize;
  color: var(--blue);
  margin-bottom: 2rem;
}

.chapter-info {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.review-wrap {
  display: flex;
  flex-direction: column;
  padding: 12px 12px 16px;
  gap: 8px;
  border: 1px solid #dedede;
  border-radius: 20px;
  background-color: var(--white);
  margin-right: 2rem;
}

.review-username {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--blue-10);
}

.review-text {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 140%;
  color: var(--blue-10);
}

.review-avatar-wrap {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: var(--blue);
  margin-right: 10px;
}

.review-avatar-wrap p {
  color: var(--white);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
}

.autor-banner-wrap {
  background-color: var(--blue);
  border-radius: 40px;
  padding: 4rem;
  color: var(--white);
}

.autor-banner-wrap.gray {
  background-color: var(--blue-background);
}

.autor-banner-desc {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10rem;
}

.autor-banner-desc.gray {
  color: var(--gray-text);
}

.autor-banner-tag {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  margin-bottom: 6rem;
}

.autor-banner-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 77px;
  margin-bottom: 2rem;
}

.autor-banner-title.gray {
  color: var(--gray-text);
}

.reader-header {
  background-color: #f4f4f4;
  padding: 1.4rem 6rem;
}

.reader-header .holter {
  margin-top: 10px;
  text-align: center;
}

.discrete-slider {
  background-color: var(--blue-background);
  padding: 2.5rem;
  border-radius: 20px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.question-title {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  /* or 36px */

  /* Blue */
  color: var(--blue);
  margin-bottom: 30px;
}

.question-number {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  /* Dark Grey Text */

  color: var(--gray-text);
  margin-bottom: 8px;
}

.filter-label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #616161;
}

.MuiCheckbox-root {
  padding: 0 !important;
  margin-right: 10px !important;
}

.filter-item-wrap {
  margin-bottom: 16px;
}

.thumb-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4rem;
  margin-top: 2rem;
}

.auth-modal-wrap {
  padding: 3rem;
  background: var(--white);
  -webkit-box-shadow: 0px 4px 14px 2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 4px 14px 2px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 4px 14px 2px rgba(34, 60, 80, 0.2);
  border-radius: 20px;
  width: 40rem;
  z-index: 22;
  overflow: scroll;
}
@media (max-height: 800px) {
  .auth-modal-wrap {
    height: auto;
    max-height: 100%;
  }
  .auth-modal-wrap .auth-type-select-wrap {
    position: sticky;
  }
}

.input-field-wrap {
  border: 1px solid #898989;
  border-radius: 10px;
  padding: 5px 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}

.input-field-wrap.error {
  border-color: var(--red);
}

.error-text {
  font-size: 12px;
  color: var(--red);
}

.polca {
  margin-bottom: 16px;
}

.input-field-wrap input {
  display: flex;
  outline: none;
  font-size: 16px;
}

.input-field-wrap p {
  font-size: 12px;
  line-height: 24px;
  color: #c7c7c7;
}

.authorization-label {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 120% */

  display: flex;
  align-items: center;

  /* Text black */

  color: #0f0f0f;
  margin-bottom: 15px;
}

.author-banner-image {
  height: 455px;
  width: 455px;
  border-radius: 20px;
  object-fit: cover;
  border-radius: 20px;
}

.auth-type-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;

  /* Text gray */

  color: var(--gray20);
}

.auth-type-select-wrap {
  display: flex;
  flex-direction: row;
  margin-bottom: 14px;
  border-bottom: 1px solid #616161;
}
.auth-type-select {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 10px;
}

.auth-type-select.selected {
  border-bottom: 6px solid #0f0f0f;
}

.auth-type-select.selected .auth-type-label {
  font-weight: 700;
}

.profile-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 12px;
  min-width: 200px;
}

.profile-button img {
  filter: brightness(0%);
  margin-right: 10px;
}

.profile-button p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #595959;
}

.profile-button.selected {
  background-color: var(--blue);
}

.profile-button.selected img {
  filter: brightness(0) invert(1);
}

.profile-button.selected p {
  color: #ffffff;
}

.settings-wrap .input-field-wrap {
  border: 0;
  background-color: var(--blue-background);
}

.settings-wrap .input-field-wrap input {
  background-color: var(--blue-background);
}

.settings-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Dark Grey Text */

  color: var(--gray-text);
  margin-bottom: 16px;
}

.ic-profile {
  margin-bottom: 6px;
}

.logout-button {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (max-width: 600px) {
  .logout-button {
    display: none;
  }

  .d-sm-none {
    display: none;
  }
}

.logout-button p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #aaaaaa;
  margin-right: 10px;
}

.logout-button img {
  filter: grayscale(90%);
  width: 24px;
  height: 24px;
}

.not-found {
}

.not-found p {
  font-size: 2rem;
  color: var(--black);
  font-weight: 400;
}

.overflow-scroll {
  overflow: scroll;
  scrollbar-width: 0;
}

.overflow-scroll::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.carousel-wrap {
  display: flex;
  overflow: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: 0;
}
.carousel-wrap::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.shade-wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 21;
  display: flex;
  transition: 0.4s linear;
  align-items: flex-start;
  padding: 30px;
  justify-content: center;
}

.shade-wrap::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.auth-modal-wrap::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

@media (max-height: 750px) and (min-width: 1200px) {
  .auth-modal-wrap {
    /* transform: scale(2.4); */
    transform: scale(0.9) translateY(-5%);
  }
}

.shade-active {
  display: block;
}

.white-text {
  color: var(--white);
  font-family: "Inter";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.questions-wrap {
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.slider-wrap {
  padding: 10px 28px;
  background-color: #eff3f7;
  border-radius: 20px;
}

.modal-box {
  display: flex;
  width: 90vh;
  height: 95vh;
  background-color: #fff;
  border-radius: 20px 20px 0px 0px;
  flex-direction: column;
  padding: 50px;
  overflow: scroll;
}

.question-sec {
}

.q-sc-i {
  color: var(--dark-grey-text, #343434);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.q-sc-q {
  color: var(--blue, #213669);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 30px;
}

.child-modal-title {
  color: var(--dark-grey-text, #343434);
  /* H2 */
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
  text-align: center;
  margin-left: 10%;
  width: 80%;
}

.child-modal-description {
  color: #0f0f0f;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-bottom: 60px;
  text-align: center;
}

.poll-wrap {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}

.poll-wrap span {
  color: #0f0f0f;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.ss-a-po {
  color: #595959;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 20.4px */
  margin-bottom: 10px;
}

.result-title {
  color: var(--blue, #213669);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 36px */
  margin-bottom: 15px;
}

.result-desc {
  color: #0f0f0f;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.book_cover_big {
  width: 350px;
  height: 450px;
  object-fit: cover;
  border-radius: 20px;
}

@media screen and (max-width: 600px) {
  .about-desc {
    font-size: 12px;
    line-height: 16px;
    text-indent: 0px;
  }
  .banner-desc {
    font-size: 10px;
  }
  .banner-title {
    font-size: 18px;
  }

  .info-title {
    font-size: 12px;
  }
  .book-card-wrap h4 {
    font-size: 10px !important;
    line-height: 12px !important;
  }

  .book-card-autor {
    font-size: 12px !important;
    line-height: 16px !important;
    margin-bottom: 5px;
  }

  .book-card-autor span {
    display: none;
  }

  .book-card-wrap {
    padding: 10px;
    width: 150px;
    margin-right: 14px;
    padding-top: 18px;
    justify-content: space-between;
  }

  .book-card-wrap.full-width {
    width: 100%;
    margin: 0;
  }

  .book-card-wrap.small {
    width: 150px;
    padding: 10px;
    display: flex;
    background-color: var(--white);
    margin-right: 14px;
  }
  .book-card-image {
    width: 85px;
    height: 125px;
  }
  .carousel {
    padding: 0;
    margin-bottom: 2rem;
  }

  .banner-wrap {
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .tag-wrap {
    border-radius: 15px;
    padding: 10px;
    margin-bottom: 10px;
  }
  .banner-title {
    line-height: 22px;
    margin-bottom: 15px;
  }

  .book-cover {
    width: 80px;
    height: 110px;
    margin-right: 12px;
    border-radius: 10px;
  }
  .banner-desc {
    width: 100%;
    font-size: 12px;
  }
  .banner-desc-small {
    margin-bottom: 15px;
  }
  .banner-info {
    margin-right: 0;
  }
  .book-banner-title {
    font-size: 18px;
    line-height: 24px;
  }
  .book-banner-info {
    font-size: 12px;
    line-height: 16px;
  }

  .book-review-info {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 15px;
  }
  .book-info {
    font-size: 12px;
    line-height: 16px;
  }
  .bb-sm-1 {
    padding: 10px !important;
  }

  .review-wrap {
    width: 100%;
  }

  .autor-banner-title {
    font-size: 18px;
    line-height: 21px;
  }
  .autor-banner-desc {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 20px;
  }
  .autor-banner-wrap {
    padding: 10px;
    border-radius: 15px;
  }
  .autor-banner-image {
    height: 200px;
    border-radius: 10px;
    width: 100%;
    object-fit: cover;
    margin-bottom: 15px;
  }
  .chapter-numbers {
    font-size: 14px;
    line-height: 18px;
  }
  .chapter-info {
    font-size: 12px;
    line-height: 14px;
  }

  .reader-header {
    padding: 1rem;
  }

  .shade-wrap {
    padding: 0 !important;
  }
  .auth-modal-wrap {
    width: 100vw;
    height: 100vh;
    top: -15px;
    border-radius: 0;
    top: 0;
    left: 0rem;
    transform: scale(1);
  }

  .cat-list {
    display: flex;
    overflow: scroll;
  }

  .profile-button {
    min-width: 100px;
    align-items: center;
    justify-content: center;
    padding: 7px 15px;
    border: 1px solid var(--gray);
    margin-right: 10px;
    border-radius: 10px;
  }

  .profile-button.selected {
    border: 0;
  }

  .profile-button img {
    display: none;
  }

  .profile-button p {
    font-weight: 500;
    font-size: 12px;
    line-height: 135%;
  }

  .child-modal-title {
    font-size: 18px;
    text-align: center;
    margin-left: 0;
    width: 80%;
    margin-left: 10%;
  }
  .child-modal-description {
    font-size: 16px;
    text-align: left;
  }

  .modal-box {
    padding: 10px;
    padding-top: 20px;
    width: 100vw;
    height: 100%;
  }
  .modal-box .asdlo-ads {
    height: 90%;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
  }

  .modal-box .questions-wrap {
    gap: 15px;
  }

  .q-sc-i {
    font-size: 12px;
  }

  .q-sc-q {
    font-size: 14px;
  }

  .poll-wrap span {
    font-size: 12px;
    line-height: 14px;
  }
}

.relative {
  position: relative;
}

.google-login-btn {
  position: absolute;
  z-index: 1;
  width: 100%;
  opacity: 0.0001;
}
